import { Controller } from "stimulus"
const zxcvbn = require("zxcvbn")

export default class extends Controller {
  toggleRoleLabel(event) {
    let label = event.currentTarget.nextElementSibling;
    if (event.currentTarget.selectedIndex >= 1) {
      label.classList.add('visible');
    } else {
      label.classList.remove('visible');
    }
  }

  validateForm(event) {
    if (this.getNewPassword().length > 0) {
      let rating = this.getPasswordRating()
      if (rating.score < 3) {
        this.showAlert('The new password is too weak.')
        event.preventDefault()
      }
    }
  }

  showAlert(message) {
    let flashContainer = document.querySelector('.flash-wrapper')
    flashContainer.innerHTML = ''
    let alert = document.createElement('li')
    alert.classList.add('flash', 'flash-alert')
    let icon = document.createElement('i')
    icon.classList.add('fas', 'fa-exclamation-triangle')
    alert.appendChild(icon)
    alert.append(message)
    flashContainer.appendChild(alert)
    flashContainer.classList.remove('animate', 'flashAnimation')
    setTimeout(function() {
      flashContainer.classList.add('animate', 'flashAnimation')
    }, 0)
  }

  getNewPassword() {
    return document.querySelector('#user_password').value
  }

  getPasswordRating() {
    let email = document.querySelector('#user_email').value
    let name = document.querySelector('#user_name').value
    let candidate = this.getNewPassword()
    return zxcvbn(candidate, [email, name])
  }

  ratePassword(event) {
    //renable the form
    document.querySelector('input[type=submit]').disabled = false

    let result = this.getPasswordRating()
    let meter = document.querySelector('.password-meter')
    meter.classList.remove('password-meter-0', 'password-meter-1', 'password-meter-2', 'password-meter-3', 'password-meter-4')
    meter.classList.add('password-meter-' + result.score)

    let infoText = document.querySelector('.password-meter__info-text')

    let rating = ""
    switch(result.score) {
      case 0:
      case 1:
        rating = "Very weak"
        break
      case 2:
        rating = "Weak"
        break
      case 3:
        rating = "Good"
        break
      case 4:
      default:
        rating = "Strong"
        break
    }

    infoText.innerText = rating
  }
}
